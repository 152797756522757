<template>
  <div class="wrap-info">
    <div class="wrap-mp">
      <div class="wp">
        <span>补充资料{{ ProjectName ? " - " + ProjectName : "" }}</span>
        <!-- <div class="wrapbtn">
              <el-button
                size="mini"
                type="primary"
                @click="dialogFileVisible = true"
                >附件</el-button
              >
              <el-button size="mini" type="danger" @click="remove"
                >删除</el-button
              >
              <el-button size="mini" type="primary">下载</el-button>
            </div> -->
      </div>
      <div class="wrap-tree">
        <el-tree
          ref="tree"
          :data="data"
          node-key="id"
          :props="defaultProps"
          :default-expand-all="true"
          show-checkbox
        >
          <template #default="{ node, data }">
            <div class="custom-tree-node">
              <span>{{ node.label }}</span>
              <span v-if="data.children.length > 0">
                <i> {{ data.children.length }}</i>
              </span>
              <span v-else>
                <el-upload
                  v-show="!node.data.showButton"
                  class="upload-demo"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :show-file-list="false"
                  multiple
                  :limit="1"
                  :on-exceed="handleExceed"
                  :on-success="
                    (res, file, fileList) => {
                      return uploadSuccess(res, file, fileList, node);
                    }
                  "
                  :file-list="fileList"
                >
                  <el-button size="mini" type="primary">上传</el-button>
                  <!-- <template   #default="{ fileList }">
                        <el-button type="primary" size="mini"> 预览{{fileList}} </el-button>
                      <el-button
                        type="danger"
                        size="mini"
                        @click="beforeRemove()"
                      >
                        删除
                      </el-button>
                      </template> -->
                </el-upload>
                <span v-show="node.data.showButton">
                  <el-button type="primary" size="mini" @click="getFileList">
                    预览
                  </el-button>
                  <el-button
                    style="margin-right: 10px"
                    type="danger"
                    size="mini"
                    @click="beforeRemove(node.data.file, node.data.fileList)"
                  >
                    删除
                  </el-button>
                </span>
              </span>
            </div>
          </template>
        </el-tree>
      </div>
    </div>
  </div>
  <!-- 图片预览 -->
  <el-image-viewer
    lazy
    :url-list="fileContent"
    @close="
      () => {
        showViewer = false;
      }
    "
    v-if="showViewer"
  >
    <template #error>
      <div class="image-slot">
        <el-icon><Loading /></el-icon>
      </div>
    </template>
  </el-image-viewer>
  <!-- txt预览 -->
  <el-dialog v-model="dialogFileVisible" title="补充资料">
    <!-- 图片 txt 其他文件 -->
    <!-- <a href="https://oss.walkinfo.com/zhch/2021/5d982ece-be0e-4909-bfe1-7894a057ebbb/公开信息文件/26424747-f92d-4f70-b199-e65cb4749245.pdf">点击下载</a> -->
    <span>下载:</span><a :href="fileContent[0]" target="mainFrame">下载附件</a>()

    <iframe style="width: 100%; height: 100%" name="mainFrame"> </iframe>
  </el-dialog>
</template>

<script>
let id = 1000;
console.log(id);
// import UploadFile from "../../components/Upload";
import { Loading } from "@element-plus/icons";
import { matchType } from "@/layout/config/tagsm.js";
import { ElMessage } from "element-plus";

export default {
  name:'file',
  components: {
    // UploadFile,
    Loading,
  },
  mounted() {},
  data() {
    return {
      fileList: [], //文件数组
      fileContent: [], //文件
      PicOrTxt: "",
      showViewer: false,
      ProjectName: "", //项目名称
      data: [
        {
          id: 1,
          name: "补充资料",
          children: [
            {
              id: 2,
              name: "身份证明材料",
              children: [
                {
                  id: 21,
                  name: "测试文件1",
                  children: [],
                  showButton: false,
                  file: "",
                  fileList: [],
                },
                {
                  id: 22,
                  name: "测试图片2",
                  children: [],
                  showButton: true,
                  file: "",
                  fileList: [],
                },
                {
                  id: 23,
                  name: "测试文件3",
                  children: [],
                  showButton: false,
                  file: "",
                  fileList: [],
                },
              ],
            },
            {
              id: 3,
              name: "证明材料2",
              children: [
                {
                  id: 31,
                  name: "测试文件31",
                  children: [],
                  showButton: false,
                  file: "",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "name",
        disabled: "disabled",
      },
      dialogFileVisible: false,
    };
  },
  methods: {
    //接收父组件信息 根据。。查询文件内容 查询的是tree节点的showButton
    //点击第一次是获取treeJson，点击对应树枝获取文件类型和文件
    getLandFile(files) {
      // console.log("接收父组件信息:", files);
      this.ProjectName = files.ProjectName;
      this.getTreeList(files.Id);
    },
    //模拟ajax请求 这里获取树形数据
    async getTreeList(type) {
      // console.log("获取树形数据：", type);
      let res = await this.$ajax.post("/api/file/fileData", {
        name: type,
      });
      console.log(res);
      if (res.status == 200) {
        this.data[0].children[0].children = res.data.list;
      }
      console.log(this.data);
    },
    //模拟ajax请求 这里获取图片
    async getFileList(type) {
      this.fileContent = [];
      // this.dialogFileVisible = true;
      // console.log("父传子的：", type);
      let res = await this.$ajax.get("/api/file/showFile", {
        name: type,
      });
      console.log(res);
      if (res.status == 200) {
        this.fileContent.push(
          "https://scpic.chinaz.net/files/pic/pic9/202111/bpic24736.jpg"
          // "https://nanxun.zhch.walkinfo.com/file/getFile?documentId=24d22850-5755-4e6d-9f3f-993f99abb22b"
          // "https://oss.walkinfo.com/zhch/2021/5d982ece-be0e-4909-bfe1-7894a057ebbb/公开信息文件/26424747-f92d-4f70-b199-e65cb4749245.pdf"
        );
      }
      let ptArr = this.fileContent[0].split(".");
      //拿到文件的后缀名
      let fileStyle = ptArr[ptArr.length - 1];
      //判断拿到的文件是什么类型
      this.PicOrTxt = matchType(fileStyle);
      if (this.PicOrTxt === "image") {
        this.showViewer = true;
      } else {
        this.dialogFileVisible = true;
      }
      // console.log(this.fileContent);
      console.log("获取的文件类型：", this.PicOrTxt);
    },
    //上传成功提示
    uploadSuccess(res, file, fileList, node) {
      node.data.showButton = true;
      node.data.file = file;
      // node.data.fileList=fileList,
      console.log("上传成功", res, file, fileList);
      ElMessage({
        message: "上传成功",
        type: "success",
      });
      // console.log('上传里',response, file, fileList,node);
    },

    handleRemove(file, fileList) {
      console.log("控制删除", file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    //上传文件超过一个时提醒
    handleExceed() {
      ElMessage({
        message: "请勿重复上传",
        type: "warning",
      });
    },
    //node节点中的删除文件
    // fileRemove(node){
    //   console.log(node);
    //   return this.$confirm(`是否删除 ${node.name} ?`);
    // },
    //上传组件的移除
    beforeRemove(file, fileList) {
      console.log(file, fileList);
      return this.$confirm(`是否删除 ${file.name} ?`);
    },
    //得到子组件传来的file
    getFlieData(data) {
      this.append(data);
    },
    //添加节点
    append(data) {
      //   console.log(data);
      for (let a = 0; a < data.length; a++) {
        if (!data[a].children) {
          data[a].children = [];
          this.data[0].children.push(data[a]);
        }
      }
      this.data = [...this.data];
      console.log("补充资料内：", this.data);
    },
  },
};
</script>

<style scoped>
.upload-demo {
  display: inline-block;
  margin-right: 10px;
}
/*  */
.wrap-tree {
  border-top: 1px solid #d9d7d7;
}
.wrap-tree :deep(.el-tree-node) {
  margin-top: 5px;
}
.wrapbtn {
  float: right;
  padding-top: 12px;
  padding-right: 5px;
}
/* 、、、 */
.wp > span {
  font-size: 18px;
  padding-left: 5px;
  line-height: 40px;
}
.wrap-mp {
  /* border: 1px solid; */
  background: #ffffff;
  height: 650px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.wrap-info {
  /* border:1px solid red; */
  /* padding-left: 10px; */
  /* padding-right: 10px; */
}
.custom-tree-node {
  width: 100%;
}
.custom-tree-node span:first-child {
  align-items: center;
  vertical-align: middle;
  /* line-height: 38px; */
}
.custom-tree-node span:last-child {
  float: right;

  /* display: inline-block; */
}
.custom-tree-node span:last-child i {

  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777777;
  border-radius: 10px;
  margin-right: 10px;
}
</style>
